<template>
  <div>
    <CCardBody class="c-card-body">
      <router-link :to="`/zodiac-signs/create`">
        <CButton
          color="success"
        >
          Создать знак зодиака
        </CButton>
      </router-link>
      <br>
      <br>
      <CDataTable
        :items="zodiacSigns"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter = "{label: 'Поиск', placeholder: 'найти...'}"
      >
        <template #name="{ item }">
          <td>
            <router-link :to="{ name: 'zodiac-sign-edit', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </td>
        </template >
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>
.test {
  background-color: red;
}
.c-card-body {
  background-color: #fefefe;
}
table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}
.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
tr:hover .td {
  background: #e8edff;
}
.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}
.item-right {
  text-align: right;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
const fields = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "name",
    label: "Название",
  }
];
export default {
  name: "ZodiacSignsList",
  data() {
    return {
      fields
    };
  },
  computed: {
    ...mapState({
      zodiacSigns: (state) => state.zodiacSigns.all,
    }),
  },
  created() {
    this["zodiacSigns/getAll"]();
  },
  methods: {
    ...mapActions(["zodiacSigns/getAll"]),
  },
};
</script>
